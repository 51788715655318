import React, { ReactNode, useEffect, useRef } from 'react';

import style from './Backdrop.scss';

import cn from 'classnames';

export interface IBackdropProps {
  onOutsideClick: () => void;
  children: ReactNode;
  className?: string;
  isOpen: boolean;
}

function noScroll() {
  window.scrollTo(0, 0);
}

export default function Backdrop(props: IBackdropProps) {
  const { children, isOpen, onOutsideClick } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent) => {
    const currentEl = wrapperRef.current;
    if (currentEl && !currentEl.contains(event.target as HTMLElement)) {
      document.removeEventListener('click', handleClick);
      onOutsideClick();
    }
  };

  useEffect(() => {
    if (isOpen) {
      // add listener to disable scroll
      window.addEventListener('scroll', noScroll);

      document.addEventListener('click', handleClick);
    }
    return () => {
      // Remove listener to re-enable scroll
      window.removeEventListener('scroll', noScroll);

      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  const { className } = props;

  return (
    <div
      className={cn(className, { [style.backdrop]: props.isOpen })}
      data-testid="backdrop-parent"
      data-open={isOpen}
    >
      <div ref={wrapperRef} data-testid="backdrop-children">
        {children}
      </div>
    </div>
  );
}
