import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
}

const ErrorIcon = (props: IIconProps) => {
  const { width = 22, className } = props;
  const height = (width * 22) / 22;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <circle stroke="#FF2642" cx="12" cy="12" r="9.6" />
        <path
          d="M11.994 16.084c.54 0 .972-.408.972-.924 0-.528-.432-.936-.972-.936a.943.943 0 0 0-.96.936c0 .516.432.924.96.924zm-.576-2.664l-.216-6h1.62l-.228 6h-1.176z"
          fill="#FF2642"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default React.memo(ErrorIcon);
