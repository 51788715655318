import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CloseIcon = (props: IIconProps) => {
  const { width = 18, height = 17, className } = props;

  return (
    <svg width={width} height={height} className={className} viewBox="0 0 18 17">
      <g stroke="#111" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
        <path d="M1.929 1.429L16.07 15.57M16.071 1.429L1.93 15.57" />
      </g>
    </svg>
  );
};

export default React.memo(CloseIcon);
