import React from 'react';

import ArrowIcon from '../../images/Arrow';

import style from './SimplePagination.scss';

import cn from 'classnames';

interface IPaginationProps {
  numberOfPages: number;
  humanPageNumber: number;
  onPageChange: (nextPageNumber: number) => void;
  className?: string;
}

class SimplePagination extends React.PureComponent<IPaginationProps> {
  render() {
    const { humanPageNumber, numberOfPages, onPageChange } = this.props;

    if (numberOfPages === 1) {
      return null;
    }

    const pageLinks = [];

    const createLink = (pageNumber: number) =>
      pageNumber === humanPageNumber ? (
        <span key={pageNumber} className={style.active}>
          {pageNumber}
        </span>
      ) : (
        <button key={pageNumber} onClick={onPageChange.bind(null, pageNumber)}>
          {pageNumber}
        </button>
      );

    // Do not show ellipsis if there's less than 9 pages.
    if (numberOfPages < 9) {
      for (let i = 1; i < numberOfPages + 1; i += 1) {
        pageLinks.push(createLink(i));
      }
    } else {
      // Always show the first page link.
      pageLinks.push(createLink(1));

      if (humanPageNumber < 5) {
        for (let i = 2; i < Math.max(4, humanPageNumber + 2); i += 1) {
          pageLinks.push(createLink(i));
        }
        pageLinks.push(
          <span key={Math.max(4, humanPageNumber + 2)} className={style.link}>
            ...
          </span>
        );
      } else if (humanPageNumber >= numberOfPages - 3) {
        pageLinks.push(
          <span key={humanPageNumber - 4} className={style.link}>
            ...
          </span>
        );
        for (let i = Math.min(humanPageNumber - 1, numberOfPages - 2); i < numberOfPages; i += 1) {
          pageLinks.push(createLink(i));
        }
      } else {
        pageLinks.push(
          <span key={humanPageNumber - 2} className={style.link}>
            ...
          </span>
        );
        pageLinks.push(createLink(humanPageNumber - 1));
        pageLinks.push(createLink(humanPageNumber));
        pageLinks.push(createLink(humanPageNumber + 1));
        pageLinks.push(
          <span key={humanPageNumber + 2} className={style.link}>
            ...
          </span>
        );
      }

      // Always show the last page link.
      pageLinks.push(createLink(numberOfPages));
    }

    return (
      <nav className={cn(this.props.className, style.pagination)} role="navigation">
        <div>
          {humanPageNumber === 1 ? (
            <span className={cn(style.arrow, style.arrowDisabled)}>
              <ArrowIcon />
            </span>
          ) : (
            <button className={style.arrow} onClick={onPageChange.bind(null, humanPageNumber - 1)}>
              <ArrowIcon />
            </button>
          )}
        </div>
        <div className={style.pages}>{pageLinks}</div>
        <div>
          {humanPageNumber === numberOfPages ? (
            <span className={cn(style.arrow, style.arrowDisabled)}>
              <ArrowIcon rotate={180} />
            </span>
          ) : (
            <button className={style.arrow} onClick={onPageChange.bind(null, humanPageNumber + 1)}>
              <ArrowIcon rotate={180} />
            </button>
          )}
        </div>
      </nav>
    );
  }
}

export default SimplePagination;
