import React from 'react';

import style from './LoadingDots.scss';

import cn from 'classnames';

export interface ILoadingDotsProps {
  className?: string;
  variant?: 'dark' | 'light';
}

export function LoadingDots(props: ILoadingDotsProps) {
  const { className, variant = 'light' } = props;

  return (
    <div className={cn(className, style.loader, style[variant])}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
